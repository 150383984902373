import BLOG_BANNER from  '../../../assets/images/blog/blogs.png'

import MANAGING_DELAYED_ONBOARDING from  '../../../assets/images/blog/managing-delayed-onboarding.jpeg'

import BUILD_A_CAREER_IN_CLOUD_COMUTING from  '../../../assets/images/blog/build-a-career-in-cloud-computing.jpeg'
import CLOUD_COMUTING from  '../../../assets/images/blog/cloud-computing.png'
import DEMAND_FOR_CLOUD_COMUTING from  '../../../assets/images/blog/demand-for-cloud-computing.jpeg'
import SKILL_NEEDED_FOR_CLOUD_COMUTING from  '../../../assets/images/blog/skill-needed-cloud-computing.jpeg'

import UPSKILLING_AND_RESKILLING from  '../../../assets/images/blog/upskilling-and-reskilling.png'
import UPSKILLING_AND_RESKILLING2 from  '../../../assets/images/blog/upskilling-and-reskilling-2.png'
import UPSKILLING_AND_RESKILLING3 from  '../../../assets/images/blog/upskilling-and-reskilling-3.png'

import Indian_IT_Sector_Facilitates_3845113ef856 from  '../../../assets/images/blog/indian-it-sector-facilitates-jobs-in-funding-winter.jpeg'
import Indian_IT_Sector_Facilitates_3845113ef856_2 from  '../../../assets/images/blog/indian-it-sector-facilitates-3845113ef856-2.jpeg'

import Upskilling_The_New_Age_a8bbdb8ccc86 from  '../../../assets/images/blog/upskilling-the-new-age-growth-hack-a8bbdb8ccc86.jpeg'
import Upskilling_The_New_Age_a8bbdb8ccc86_2 from  '../../../assets/images/blog/upskilling-the-new-age-growth-hack-a8bbdb8ccc86-2.jpeg'
import Upskilling_The_New_Age_a8bbdb8ccc86_3 from  '../../../assets/images/blog/upskilling-the-new-age-growth-hack-a8bbdb8ccc86-3.jpeg'
import Upskilling_The_New_Age_a8bbdb8ccc86_4 from  '../../../assets/images/blog/upskilling-the-new-age-growth-hack-a8bbdb8ccc86-4.jpeg'

import Startup_Hiring_Trend_In_2022_175664951461 from  '../../../assets/images/blog/startup-hiring-trend-in-2022-175664951461.jpeg'
import Startup_Hiring_Trend_In_2022_175664951461_2 from  '../../../assets/images/blog/startup-hiring-trend-in-2022-175664951461-2.jpeg'
import Startup_Hiring_Trend_In_2022_175664951461_3 from  '../../../assets/images/blog/startup-hiring-trend-in-2022-175664951461-3.jpeg'

import Maintaining_Work_Life_Balance_93292b01d06c from  '../../../assets/images/blog/maintaining-work-life-balance-for-increased-productivity-93292b01d06c.jpeg'
import Maintaining_Work_Life_Balance_93292b01d06c_2 from  '../../../assets/images/blog/maintaining-work-life-balance-for-increased-productivity-93292b01d06c-2.jpeg'
import Maintaining_Work_Life_Balance_93292b01d06c_3 from  '../../../assets/images/blog/maintaining-work-life-balance-for-increased-productivity-93292b01d06c-3.jpeg'


const BlogAssets = {
    BLOG_BANNER,

    MANAGING_DELAYED_ONBOARDING,

    BUILD_A_CAREER_IN_CLOUD_COMUTING,
    CLOUD_COMUTING,
    DEMAND_FOR_CLOUD_COMUTING,
    SKILL_NEEDED_FOR_CLOUD_COMUTING,

    UPSKILLING_AND_RESKILLING,
    UPSKILLING_AND_RESKILLING2,
    UPSKILLING_AND_RESKILLING3,

    Indian_IT_Sector_Facilitates_3845113ef856,
    Indian_IT_Sector_Facilitates_3845113ef856_2,

    Upskilling_The_New_Age_a8bbdb8ccc86,
    Upskilling_The_New_Age_a8bbdb8ccc86_2,
    Upskilling_The_New_Age_a8bbdb8ccc86_3,
    Upskilling_The_New_Age_a8bbdb8ccc86_4,

    Startup_Hiring_Trend_In_2022_175664951461,
    Startup_Hiring_Trend_In_2022_175664951461_2,
    Startup_Hiring_Trend_In_2022_175664951461_3,

    Maintaining_Work_Life_Balance_93292b01d06c,
    Maintaining_Work_Life_Balance_93292b01d06c_2,
    Maintaining_Work_Life_Balance_93292b01d06c_3
    
}

export default  BlogAssets;