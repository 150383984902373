import Banner from '../../../assets/images/banner-partner.png'
import TRUSTED_BRAND from  '../../../assets/images/trust-banner.png'
import TRUSTED_BRAND_MOBILE from  '../../../assets/images/trust-banner-mobile.png'
import INCREASE_REACH from '../../../assets/images/partner-card1.png'
import CURATED_PROGRAMS from '../../../assets/images/partner-card2.png'
import TESTIMONIAL_NOHUB_ACADEMY from '../../../assets/images/nohub.png'


const UpskillingPartnersAssets = {
    Banner,
    TrustedBrand:{
        Mobile:TRUSTED_BRAND_MOBILE,
        Desktop:TRUSTED_BRAND
    },
    Features:{
        IncreaseReach:INCREASE_REACH,
        CuratedPrograms:CURATED_PROGRAMS
    },
    Testimonial:{
        NohubAcademy:TESTIMONIAL_NOHUB_ACADEMY
    }

}


export default UpskillingPartnersAssets;