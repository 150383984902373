import {css} from '@emotion/react';
import { AppColors } from '../../../../utils/consts/app_colors';


export const CarouselStyles = {

    carousel: css({
        "--swiper-pagination-color":AppColors.PRIMARY_MAIN,
        "--swiper-pagination-bullet-border-radius":'0',
        "--swiper-navigation-color":AppColors.PRIMARY_MAIN,
        "--swiper-navigation-sides-offset": "16px",
        
    }),
}



