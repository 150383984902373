import Error from '../../assets/images/oops.png'

import HomeAssets from './app_assets/home_assets'
import CompanyAssets from './app_assets/company_assets'
import JobSeekersAssets from './app_assets/jobseekers_assets'
import UpskillingPartnersAssets from './app_assets/upskilling_partners_assets'
import AboutUsAssets from './app_assets/about_us_assets'
import ContactUsAssets from './app_assets/contact_us_assets'
import KnowMoreFromAssets from './app_assets/know_more_form_assets'
import HRServicesAssets from './app_assets/hr_services_assets'
import BlogAssets from './app_assets/blog_assets'





const Error404={
    Error

}




export const AppAssets = {
    HomeAssets,
    CompanyAssets,
    JobSeekersAssets,
    UpskillingPartnersAssets,
    AboutUsAssets,
    ContactUsAssets,
    KnowMoreFromAssets,
    HRServicesAssets,
    Error404,
    BlogAssets

}