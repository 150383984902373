import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AppAssets } from "../../../utils/consts/app_assets"

const Assets = AppAssets.BlogAssets;

const Banner = Assets.BLOG_BANNER

const Blogs = [
    {
        id:'managing-delayed-onboarding-strategies-for-students-704dbab5f0eb',
        title:'Managing Delayed Onboarding – Strategies for Students',
        image:Assets.MANAGING_DELAYED_ONBOARDING,
        published:'Mar 3, 2023',
        publisher:'UDEN',
        keywords:['student','managing','management','strategies','motivation','prepare'],
        body:[
            {
                paragraphs:[
                    'As is commonly known, the campus hiring season in India commences almost a year prior to students\' graduation. When students secure a job through the campus program, their parents, siblings, and professors also experience immense elation. It is a transformative event for nearly everyone involved, as these students prepare to embark on their professional journey under the banner of a corporate organization.',
                    'However, this year, the situation is a little different. It is unfortunate that some organizations have delayed the onboarding of campus hires who were made an offer to join. This delay is likely due to the current global economic conditions and should be respected. However, this delay can leave affected students feeling demotivated and worried about their career and financial prospects. On the other hand, it presents an opportunity for them to invest in themselves and use their time productively. This will not only add value in the long run but also create other opportunities if they are interested in pursuing them.',
                ]
            },
            {
                title:'To make the most of this situation, students can develop the following action plan:',
                paragraphs:[
                    {
                        bullets:{
                            paragraphs:[
                                'Refresh technical skills: Students should refresh their programming fundamentals, algorithms, and coding principles. They should identify their core technical strengths, enroll in a refresher course, and consider taking certification exams.',
                                'Focus on soft skills: soft skills are essential for career growth. Students should focus on improving communication skills, time management skills, teamwork, and other soft skills.',
                                'Keep up with industry trends: The IT industry is constantly evolving, and students need to keep up with the latest industry trends and technological advancements. They can read blogs, attend technology seminars and webinars, write about their insights, and network with industry professionals using LinkedIn.',
                                'Seek mentoring: Students should seek out mentors who can help them navigate this situation and guide them in building their long-term careers. They can learn about the corporate life cycle, develop their values and ethics, and position themselves as strong professionals and future leaders.'
                            ]
                        }

                    }
                ]
            },
            {
                paragraphs:[
                    'In summary, the idea is not to be idle but to invest in oneself and create long-term value. By following this action plan, students can use this delay in their onboarding to their advantage and emerge as stronger professionals in the future.',
                    'At UDEN, we are committed to making this happen for you. Our specialized and carefully curated technical courses, including Java, MERN, Embedded Systems, IoT, and Python with Data Analytics, are designed to add value and enhance your career prospects when you join prospective organizations. We are confident that these courses will enable you to excel in your field and achieve your professional goals.',
                    'To College Training and Placement Officers: You have played a fantastic role in securing career opportunities for your students. We applaud your efforts and would like to offer further insights in our upcoming article, which will provide you with a perspective on additional steps you can take during these challenging times. Stay tuned for more information.'
                ]
            },
        ]
    },
    {
        id:'build-a-career-in-cloud-computing-5943d2beb4ef',
        image:Assets.BUILD_A_CAREER_IN_CLOUD_COMUTING,
        title:'Build a Career in Cloud Computing',
        published:'Aug 23, 2022',
        publisher:'UDEN',
        keywords:['cloud','cloud computing','development','saas','csps','aws','gcp','paas','iaas','deployment','hosting','web','development','devops','infrastructure'],
        body:[
            {
                paragraphs:[
                    'While businesses are gradually reducing their investments in on-premises data centers and servers, the trend toward cloud adoption has seen a significant rise in recent years, particularly during the COVID-19 pandemic, when businesses were forced to enable the technology to support more remote, distributed workforces. There is currently a surge in demand for employee productivity and collaboration tools like Google Docs, as well as streaming services like Netflix and Amazon Prime Video, etc. For its operational management cloud computing skills are much required among IT professionals. As cloud adoption is growing, so does the demand for IT professionals with cloud computing skills.',

                ]
            },
            {
                title:'What is cloud computing?',
                paragraphs:[
                    'Cloud services are classified into three types: SaaS (software as a service) refers to on-demand hosted services that provide users to access business applications. Platform as a service (PaaS)—cloud-based web services and application deployment. Furthermore, infrastructure as a service (IaaS)—virtualized computing resources delivered over the internet, which frequently include storage. Cloud services are appealing to many businesses because they eliminate the need to purchase and maintain their own physical technology products, software, storage, and infrastructure.',
                    {
                        image:Assets.CLOUD_COMUTING
                    },
                    'A few large public Cloud Service Providers (CSPs) and their products currently dominate the cloud computing world. AWS (Amazon Web Services), Google Cloud Platform (GCP), and Microsoft Azure are among them. These vendors can provide the same service to hundreds of thousands, or even millions, of customers of various sizes and industries at a reasonable price. However, cloud computing is deeply embedded in all aspects of business and personal life, and it is here to stay. As a result, an increasing number of companies are actively looking for qualified and experienced cloud experts in a variety of fields, including engineering, database management, programming, development, security, and analytics.',
                ]
            },
            {
                title:'The demand for cloud computing',
                paragraphs:[
                    'Currently, there are more than 1 lac open jobs related to Cloud operations and computing in India. MyGov Saathi, Curfew ePass, the Covid-19 repository, Aarogya Setu, and CoWIN are just a few of the cloud-based projects whose timely launches had increased cloud usage throughout the pandemic. According to NASSCOM, it has the potential to boost the nation\'s GDP by $380 billion and significantly enhance citizen services in the fields of healthcare, financial access, and democratizing education for all. Although it is still in its infancy, its market has grown faster than the mature market by growing at a CAGR of 40%. The report also warns that India might lose $118 billion in GDP contribution and five million job chances by 2026 if firms and governments are hesitant to adopt cloud computing.',
                    {
                        image:Assets.DEMAND_FOR_CLOUD_COMUTING
                    },
                ]
            },
            {
                title:'Skills needed to learn cloud computing?',
                paragraphs:[
                    'Cloud computing, along with artificial intelligence and big data, is the most sought-after tech skill according to LinkedIn. This presents an opportunity for those willing to take on the challenge of learning the fundamentals and more advanced cloud computing skills. CSP is a good place to start, as programming languages like Java, JavaScript, and Python, are well suited for cloud-based software. Creating database-powered applications such as SQL, as well as data structure concepts such as data, schemas, and application programming interfaces (APIs) such as REST and GraphQL are the top cloud computing skills.',
                    'Furthermore, database management, including relational databases, NoSQL, graph databases, and data warehouse offerings; knowledge of networking fundamentals; development and operations (DevOps) with workflows that commonly involve the use of CI/CD services; machine learning and AI; cloud security; hybrid cloud; storage management, such as personal, public, private, or hybrid and so on; container technologies, such as Docker and Kubernetes, will increase the likelihood of securing the employment in cloud computing role.',
                    'Besides, data and application migration and deployment, include: automation, cloud orchestration, Linux, performance testing, metrics and analytics, cost and workload management, change management, cloud partnering, serverless computing capabilities, and infrastructure as code are necessary to learn. DevOps is an important part of the plethora of operational knowledge. DevOps is a popular software development method that considers the entire software lifecycle, from planning to maintenance. It is derived from the term "Developmental Operations." The DevOps methodology enables businesses to automate certain updates and distribute them much more quickly and efficiently. It\'s an important development process in the world of cloud computing, so adding DevOps to your skill set is a wise move.',
                    {
                        image:Assets.SKILL_NEEDED_FOR_CLOUD_COMUTING
                    },
                ]
            },
            {
                title:'Best learning resource',
                paragraphs:[
                    'There are numerous learning resources available to assist you in developing and continually improving your cloud computing skills. Many businesses also provide on-the-job training. Every major cloud provider has its own set of certifications, and there are a plethora of additional third-party certifications that are globally recognized, such as AWS, Microsoft Azure, GCP, IBM, and so on, but the most recent and up-to-date knowledge with industry updates is widely available with UDEN - an AI-enabled hiring and skilling solution provider that is widely regarded as an industry leader in skilling programmes.',
                    'If you have just completed a four-year degree, the rapid advancement of technology and the sheer complexity of the digital age may not have enabled you with all of the necessary skills for your chosen discipline. Cloud computing may be the way for you to go and UDEN can help you learn the latest cloud computing skills, with guided supports for better placement opportunities.'
                ]
            }
        ]

    },
    {
        id:'upskilling-and-reskilling-the-essentials-for-a-thriving-deepTech-ecosystem-06773fb88dd1',
        image:Assets.UPSKILLING_AND_RESKILLING,
        title:'Upskilling and Reskilling- The Essentials for a thriving DeepTech ecosystem',
        published:'Jul 26, 2022',
        publisher:'UDEN',
        keywords:['skill','upskill','deeptech','ecosystem','prepare','job','companies','company','employer','employee','business','startup','computing','demand','infrastructure','aws','stack','devops'],
        body:[
            {
                paragraphs:[
                    'India is experiencing a significant increase in the demand for tech services due to rising business expectations. In FY 2022, the industry generated $227 billion, up $30 billion over the previous year, growing at a rate of 15.5%. Startups in recent information technologies called Deep Tech, such as AI, quantum computing, augmented reality and virtual reality, cloud computing, cyber security, nanotechnology, blockchain, autonomous robotic systems, 3D printing, have grown at a CAGR of 40% since 2014.',
                    {
                        image:Assets.UPSKILLING_AND_RESKILLING2
                    },
                    'In the near future, it is expected to grow even more and gain momentum. A strong technical background in any area of DeepTech is a prerequisite for entry-level and middle-level employees. In order to capitalize on current advantages, it is necessary to develop new courses in IT-related fields such as artificial intelligence (AI), cybersecurity, blockchain, cloud computing, etc. To excel in these fields, technical skills like MERN Full Stack, Data Science, AWS+DEVOPS, Python, Data Analytics, Java, R, and many more are necessary. The constant advancement of technology has accelerated obsolescence. Therefore, it is essential for businesses and employees to constantly reskill if they want to remain relevant in the market. This has never been more evident than in the Indian IT services sector today.',
                    'The reskilling centers are mostly supported by faculty drawn from the industry. That\'s because reskilling requires an understanding of the path that the industry will follow. Many institutions have introduced unique online programmes in recent years to help the workforce get ready for opportunities. Additionally, Edtech platforms contribute greatly to addressing the need for reskilling. As one of these platforms, UDEN, a Bangalore-based leading firm that specializes in skilling, reskilling, and hiring, plays a key role in upskilling freshers and reskilling employees of companies in order to help them adapt to new technology trends.'
                ]
            },
            {
                title:'Why reskilling is at high demand?',
                paragraphs:[
                    'According to a Linkedin report published at the beginning of this year, in India, 82 percent of workers are pondering change. The "Great Resignation," a post-pandemic trend in the industries where people leave their employers for freelance work or remote projects, is also being covered by national and international media, indicating a significant rise in the demand for reskilling. The Mercer\'s 2022 Global Talent Trends study, finds that the right talent at the right price at the right time is still a major challenge. A recent report indicates that employers are placing a high priority on workforce upskilling and reskilling in the face of widespread workforce concerns such as talent acquisition, employee engagement, and productivity.',
                    {
                        image:Assets.UPSKILLING_AND_RESKILLING3
                    },
                    'Many Edtech companies such as UDEN, Simplilearn etc. that teach skills such as data science and full stack development, are planning to hire more than 2000 people this year. These companies are strengthening their teams in India and other foreign markets to meet the rising demand for reskilling and upskilling. A growing number of companies are adapting their talent strategies to fit the changing landscape, focusing more on internal talent marketplaces and leveraging the gig economy. Companies pursuing this goal have been investing in learning initiatives and providing internal gig opportunities to fill skill gaps. But scaling effectively has proven difficult. Employees are juggling work and attempting to find time to learn new skills.',
                    'Experts say that based on the three pillars of skill, scale, and speed, India aspires to become the world\'s skill capital. Vocational skill training, especially in digital skills, is enhancing employability by presenting a wealth of job creation prospects. Rural entrepreneurship has the potential to change the game if the rural skilling programmes are carried out well. The burgeoning entrepreneurial ecosystem in India is also encouraging. Activities that require a high level of skill can increase productivity and proficiency. However, correct planning, comprehension of the nuances of skill demands, and assuring aggressive and prompt implementation are the keys to unlocking the great potential for directing the future talent landscape.'
                ]
            }
        ]

    },
    {
        id:'indian-it-sector-facilitates-jobs-in-funding-winter-3845113ef856',
        image:Assets.Indian_IT_Sector_Facilitates_3845113ef856,
        title:'Indian IT Sector Facilitates jobs in Funding Winter',
        published:'Jun 02, 2022',
        publisher:'UDEN',
        keywords:['skill','upskill','deeptech','ecosystem','prepare','job','companies','company','employer','employee','business','startup','computing','demand','infrastructure','aws','stack','devops'],
        body:[
            {
                paragraphs:[
                    'The startup environment that has seen the highest growth in the last two years is now experiencing some attrition and layoffs. After the pandemic has passed, the global scenario is picking up speed in terms of growth, and typical industrial operations are moving along at a good clip. Other factors, such as stock market influences, could also be at play.',
                    'The current financial year is not progressing at the same rate as it was in the last two years for small and middle-level startups. While unicorns are experiencing a correction, small or average firms have begun to experience a shortage of financing. The new situation has pushed them into a challenging time, and their battle for talent acquisition is becoming increasingly difficult in the face of established competitors.',
                    'Last year, startup unicorns hired a large number of professionals and paid them big fat salaries, but this is changing as layoffs are occurring in a number of unicorns, including many other startups. According to estimates, many tech startups, including a few unicorns like Cars24, Vedantu, Mfine, Meesho, Unacademy, and others, have fired nearly 6,000 people due to poor performance or cost-cutting measures, and there have been over 8,000 layoffs in the last few months.',
                    {
                        image:Assets.Indian_IT_Sector_Facilitates_3845113ef856_2
                    },
                    'The reason for this is a lack of inflows amid global stock market crashes and rising talent acquisition costs. Now they have to compete with IT companies. Even these IT companies are observing the talent that is coming out of the startup ecosystem.',
                    'Although, the Indian IT sector has reported record attrition in the previous quarter, with TCS’ rate at 17.4%, Infosys at 27.7%, HCL Technologies NSE 2.37 % at 21.9%, Wipro at 23.8%, and Tech Mahindra NSE 4.14 % at 24%, it is gaining pace in hiring with better compensation and hikes, improved hiring and work-life balance measures with picking up the market after ending of pandemic effect.',
                    'According to Han Digital, a consulting firm, 40-50% of employees leaving startups are absorbed by IT companies, consulting and product companies, and global captive centers (GCCs). These days full-stack engineers, data engineers, product management, and DevOps are in demand at IT companies, GCCs, and Software as a Service (SaaS) startups. India’s overall IT business process management headcount currently stands at about 5.1 million, of which around 420,000 are employed by startups.',
                    'Pratham Jain, Chief Operating Officer at UDEN opines, "Currently there is a consolidation in the industry activities across the sectors. Especially the lack of direction among startups see high rate of attrition in challenging time as well as traction if they get right direction and support system. This is a short phase of correction in the tech hiring industry. In the coming six months the things should be settled with the economy coming to normal position."',
                    {
                        link:(props)=><Typography component='span' variant='inherit'>Uden.tech, a leading hiring assistance company based at Bangalore is helping jobseekers find their dream jobs in the uncertain market. For more details visit <Link underline="none" target='_blank' href='https://uden.tech'>https://uden.tech</Link></Typography>
                    },
                ]
            },
        ]

    },
    {
        id:'upskilling-the-new-age-growth-hack-a8bbdb8ccc86',
        image:Assets.Upskilling_The_New_Age_a8bbdb8ccc86,
        title:'Upskilling - The new age growth hackr',
        published:'May 20, 2022',
        publisher:'UDEN',
        keywords:['skill','upskill','deeptech','ecosystem','prepare','job','companies','company','employer','employee','business','startup','computing','demand','infrastructure','aws','stack','devops'],
        body:[
            {
                paragraphs:[
                    'In this day and age when technology is changing every other day both Freshers and working professionals alike must continue to develop in order to stay up-to-date with the quickly changing market. Even highly trained individuals must adapt to industry changes in order to compete with others for greater pay and promotions. This is due to the fact that industries are always changing, necessitating ongoing adaption to new market trends.',
                    'People who wish to advance in their careers must stay current and comprehend the changes in the industrial area in which they work. A same logic applies to businesses, since if a business wants to expand, it needs keep its personnel up to date, which is why upskilling is the best option. Upskilling is a method of acquiring the skills necessary to stay up with change. Companies that used to invest in upskilling new hires are now looking for candidates who are already trained and ready to be deployed.',
                    {
                        image:Assets.Upskilling_The_New_Age_a8bbdb8ccc86_2
                    }
                ]
            },
            {
                title:'Why is it important to improve skills?',
                paragraphs:[
                    'When it comes to the job market, relevant skills pertaining to the job are the most important thing to have. Money, it is stated, does not open many doors; but, talent and skill are significant assets that may lead to success. As a result, it is critical to broaden one\'s skills. A PWC survey done in several countries indicates that 53% of employees believe that automation will dramatically transform their work or make it obsolete in the next decade. While 61% of employees were optimistic about the impact of technology on their work, 77% said they were willing to learn new skills to secure a bright future in their careers. So, whether one is looking to make a career change, or they are new to the workforce, or wish to progress in the current role, salary, gain a hike, or grow a business, upskilling can add value to all of them.'
                ]
            },
            {
                title:'Upskilling helps in getting better jobs and salary hikes.',
                paragraphs:[
                    'Working professionals believe upskilling helps in getting better jobs and salary hikes. Many of them say they have shifted to better job roles because of upskilling.',
                    'The job market scenario has gone through a profound change post pandemic. A survey conducted by Team Lease Edtech with over 1,039 employees from across 11 sectors last year revealed that about 53% of the employees said upskilling has influenced their salary hike. Around 84% observed that upskilling helped them get better jobs or upgrade positions. 54.3% opted for upskilling courses of their own according to industry trends, reducing the dependency on employers. It indicates a shift towards continuous upskilling is the need of the hour. It has been influenced by the employee\'s aspirations to get better opportunities (23.8 %), shift to new job roles (19.3%), improve technical and soft skills (28.2%), and fear of job loss (20%).',
                    'Further, the survey showed that over 73% of the respondents believe that upskilling leads to better roles. It was noted that of the 48% of respondents who shifted to a new job role, 83.6% attribute the move to a better opportunity to their upskilling efforts.',
                    {
                        image:Assets.Upskilling_The_New_Age_a8bbdb8ccc86_3
                    }
                ]
            },
            {
                title:'Choosing Right Platform/Courses',
                paragraphs:[
                    'After analyzing and identifying skills, job seekers need to make sure to choose the right platforms or training courses for training. There are many upskilling institutions out there which provide great learning paths to the skill training of choice but it is very difficult to find the true reviews of such institutes. Here Uden.tech (a professional courses platform aggregator) can be the right choice to move ahead. They consider the learner background along with the future market prospects of a skill and ensure that the learner joins the course which is best suited to their profile and which has a good scope in the market. They provide an immersive experience to the learners through their partner institutes and ensure continuous placement assistance throughout their learning phase with the help of their Corporate partners.',
                ]
            },
            {
                title:'From the company\'s prospective',
                paragraphs:[
                    'As mentioned before employers are now preferring to hire trained candidates rather than spending time and resources on training new hires. A well trained and upskilled candidate is in high demand in the Corporate market as they are the most up-to-date about current trends and are mostly the most enthusiastic about showcasing their talent.',
                    'Most employers urge their workers to learn and grow on the job. It can be accomplished by allowing employees to work flexible hours or lowering their burden. Provide accessible training with the appropriate platform for learning at any time and from any location. Managers must take on the role of trainers, cultivating an engaging learning culture and providing a platform for upskilled employees to demonstrate their newly acquired skills.',
                    'Employee retention is improved by upskilling since employees do not want to work for a firm that does invest sufficiently in professional development. They want to learn, and they need the organization\'s help to do so. Upskilling employees reduces duplicate skills and increases motivation. Employees are more comfortable in their work environment and optimistic about their future prospects as a result of these learning chances. Training permits them to concentrate on their chosen profession. It may assist in keeping an employee engaged and productive, which benefits business growth.',
                    {
                        image:Assets.Upskilling_The_New_Age_a8bbdb8ccc86_4
                    }

                ]
            },
            {
                title:'Conclusion',
                paragraphs:[
                    'Upskilling gives job seekers a leg up on their competition and keeps them current in their sector. It not only improves a person\'s professional performance, but also their personal qualities. A constant learner will be better equipped to deal with life\'s uncertainties since they will be more informed in a fast changing digital environment. A robust platform like Uden.tech, an AI-based skill development and hiring platform, is the best corporate training platform and talent acquisition center that enables employees as well as employers of all sizes to offer placement and hiring solutions with more flexibility, amazing features, valuable integration, and an easy-to-use interface.',
                    {
                        link:(props)=><Typography component='span' variant='inherit'>Looking to upskill? Get in touch with UDEN at<Link underline="none" target='_blank' href='https://uden.tech'>https://uden.tech</Link></Typography>
                    },
                ]
            },
        ]

    },
    {
        id:'startup-hiring-trend-in-2022-175664951461',
        image:Assets.Startup_Hiring_Trend_In_2022_175664951461,
        title:'Startup Hiring Trend in 2022',
        published:'May 13, 2022',
        publisher:'UDEN',
        keywords:['skill','upskill','deeptech','ecosystem','prepare','job','companies','company','employer','employee','business','startup','computing','demand','infrastructure','aws','stack','devops'],
        body:[
            {
                paragraphs:[
                    'With the ease of the pandemic and the world returning to the pre-COVID situation, there is a profound change across the industry sectors. Last month, the country achieved a new milestone of creating 100 unicorns which have created an ample opportunity for hiring. In terms of investment among the Indian startups, last year witnessed the highest emergence as the Indian startups raised over $42 Billion, compared to $37 Billion raised by them in the previous three years combined. With the positive outlook across the industries, this year is being considered to observe the highest growth for the startup ecosystem. The faster growth of Fintech, Edtech, and D2C brands is opening avenues for newer jobs that previously didn’t exist, so the demand for skills and up-skilling is also on the rise. Now that technology has enabled innovation across sectors, there will be a demand for tech roles.',
                    {
                        image:Assets.Startup_Hiring_Trend_In_2022_175664951461_2
                    },
                    'According to estimates, government-registered startups have created nearly 7 lakh jobs in the country till now. Department for Promotion of Industry and Internal Trade (DPIIT) is focused on creating 20 lakh new jobs in the sector by officially registering 50,000 new startups, over the next 4 years. Tier II and Tier III cities account for 45 percent of all registered startups. Out of the 736 districts in the country, registered startups are present in 630 districts, and are present in every state and union territory. According to a Genius Consultants survey, over 77% of people agree that the startup space has played a considerable role in changing the employment scenario in India.',
                    'India’s industry sectors, including banking, financial services, insurance, retail, manufacturing, import & export, real estate, travel & tourism, have all experienced good hiring growth, except media & entertainment. Due to positive business plans, they have registered an overall growth of 15% year-on-year and 4% month-on-month in hiring, says MEI research. According to reports in the media Meesho, an e-commerce startup, has grown 10 times by now. They are going to add 2000 employees; Spinny, a used car buy-and-sell platform, is to add 2000 headcounts this year, reaching 6000. Besides these, Mamaearth, which became a unicorn in January 2022, is going to hire 300 people for technology, data, marketing, and sales. The recruitment platform Apna is going to add 400 professionals; digital adoption platform Whatfix is going to add 350; and Payphone is also considering mass scale hiring.',
                    {
                        image:Assets.Startup_Hiring_Trend_In_2022_175664951461_3
                    },
                    'Also, the adoption of technology across sectors has witnessed a rise in demand as more and more companies are transforming their organizational strategies and goals into technology, enabling organizations to take up the challenge coming up. Like, financial technology-enabled companies (Fintech) have seen rapid growth and are estimated to become the third-largest market in the world by 2025. India’s overall fintech market opportunity is estimated to be $1.3 Trillion, growing at a CAGR of 34% for this year, in which lending, insurance, and digital payment play an important role. With a positive outlook, demand for talent is increasing in the sector. For Fintech professionals, the most in-demand skills required are expertise in mobile and web engineering; APIs; blockchain & distributed ledger expertise (Python, Java), machine learning, knowledge of artificial intelligence (AI), DevOps (handles both operations and development concurrently), risk analytics, and the drive for automation; data science; cybersecurity expertise; top-notch programming skills and soft skills.',
                    {
                        link:(props)=><Typography component='span' variant='inherit'>Similarly, the other sectors are also bullish in their search for talent. Startup platforms, such as <Link underline="none" target='_blank' href='https://uden.tech'>Uden.tech</Link>, an AI-enabled recruitment and upskilling startup, is providing significant assistance to both employers and employees in terms of hiring and upskilling. Pratham Jain, COO of the company declared "We realize that there are many startups out there that are looking for the best suited candidates for their requirements as they need to expand their teams at a seamless pace. We are assisting some of them in their hiring drives for roles like Risk Analytics, Data Scientists and full-stack developers. Also since these startups are flush with money, they are willing to offer good hikes for the candidates they find the best-fit for their roles.”</Typography>
                    },
                    'The startup ecosystem is moving to the next level with a huge fund inflow and also a rise in the platforms for talent acquisition. Although, with startups turning into unicorns, the challenges are also there for hiring the right talent. To overcome them, taking the right decisions at a fast pace with patience and perseverance is a must. These are some excellent times for the startup ecosystem in the country and everyone is looking forward to be a part of the revolution.',
                    {
                        link:(props)=><Typography component='span' variant='inherit'>Follow us for regular updates on jobs and career guidance. Click to download the <Link underline="none" target='_blank' href='https://play.google.com/store/apps/details?id=com.digverve.uden'>UDEN App</Link> to apply for UDEN exclusive jobs.</Typography>
                    }
                ]
            },
        ]
    },


    {
        id:'maintaining-work-life-balance-for-increased-productivity-93292b01d06c',
        image:Assets.Maintaining_Work_Life_Balance_93292b01d06c,
        title:'Maintaining Work-Life Balance for Increased Productivity',
        published:'May 13, 2022',
        publisher:'UDEN',
        keywords:['skill','upskill','deeptech','ecosystem','prepare','job','companies','company','employer','employee','business','startup','computing','demand','infrastructure','aws','stack','devops'],
        body:[
            {
                paragraphs:[
                    'Today, productivity is a much sought-after subject in every strata of life, be it education sector or the corporate world. The expectation of getting faster results, either on a personal or professional level, has made people workaholics, but work-life (family time) balance is a must to remain productive.',
                    'Randstad Employer Brand Research (REBR) reports in its annual study done last year on employer branding that 65% of respondents gave importance to work-life balance rather than attractive salary and benefits. The other accounted for 62% among the Indian job seekers who gave importance to the attractive salary and benefits while choosing an employer. The research covered more than 80% of the global economy, with 34 participating countries and close to 2 lakh respondents worldwide.',
                    'The importance of work-life balance was highest among females in the age group between 25 and 54, which accounted for around 67%. To balance both work as well as family, a higher percentage of female respondents, nearly 54%, also gave more importance to the possibility of working remotely/from home compared to their male counterparts, which was 49%.',
                    {
                        image:Assets.Maintaining_Work_Life_Balance_93292b01d06c_2
                    },
                    'Many times we hear from our parents, friends, relatives, or other family members that ‘don’t die for the office work; family is also important!’ Well, it’s really important, but without work or being productive, one can’t earn happiness for the family, as by giving priority to one, the other is put on the back burner.',
                    'As per a 2019 study by monster.com, 60% working Indians rate work-life balance from average to terrible. The survey was conducted among 2000 working professionals. Significantly, as much as 45% of the respondents were from non-metros, where work-life balance is supposedly a lesser issue. It was also revealed in the study that collectively, 67% of India’s working professionals think about work when not at work. About 50% of the respondents confessed that the lack of work-life balance makes them or their partners irritable or ill-tempered. Lack of sleep, depression, anxiety, and irritability came up as the top work-related illnesses when employees are unable to balance their work and family.',
                    'It can be perfect only when the time for family and friends is only for them, and for that, one has to train his mind to be passionate about work. Love to work gives less stress and anxiety. One must understand that everyone can’t be kept happy. To maintain a healthy lifestyle, one has to learn to say no to some expectations which might affect the other part of the life, whether they are from the family or from the workplace. To remain calm, try to explain the situation. The people on the other side will definitely understand.',
                    {
                        image:Assets.Maintaining_Work_Life_Balance_93292b01d06c_3
                    },
                    'A story depicts it well when a mother comes up to her son engrossed in his work and asks, "How old are you my son?" He was surprised to get a sudden question but answered, "Why, I am 25?" She again asked, "At the same age I already had you".',
                    '"At your age, we had you and your father to live with us. Don’t you think that our work suffered at that time because there were you to be taken care of? We both always took care of everything and lived well. We were clear, never to bring work back home. You brought us so much joy because we made it to finish everything at work and leave it there," her mother narrated.',
                    'She continued, "I am blessed and feel lucky to be with you and your dad." I think I have never seen a person more productive than your dad. I still don’t know what his work was, because all he talked about was you at home. He had a perfect work-life balance. At work, he always worked, and at home, he was always with us. This is the reason he is so calm and peaceful, and so productive as well."',
                    'The moral of the story is that "It’s just one\'s mindset that has to get ready to maintain the balance."',
                    'Never bring work home, everything will balance itself'
                ]
            },
        ]
    },
    
];

const ViewMoreButton = "View More Blogs..."





export const BlogsConst = {
    Banner,
    Blogs,
    ViewMoreButton

}