import { css } from "@emotion/react";


const OfficesCardStyles = css({
    ".MuiCardHeader-root":{textAlign:'start'},
    ".MuiCardHeader-title":{fontWeight:'bold'},
    ".office-card-content":{
        paddingBottom: 10,
    },
    ".office-card-phone-text":{fontWeight:'bold',marginLeft: 10,}
})

export default OfficesCardStyles;