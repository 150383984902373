import BANNER from  '../../../assets/images/talent-banner.png'
import CERTIFICATE from  '../../../assets/images/certificate.png'
import AWS from  '../../../assets/images/aws.png'
import CEO from  '../../../assets/images/leaders/jay-bhaskar.png'
import COO from  '../../../assets/images/leaders/PrathamJain.png'
import CSO from  '../../../assets/images/leaders/RanjanTayal.png'
import CCO from  '../../../assets/images/leaders/Vivek-Vinayak-Purekar.jpeg'
import TR_ANAND from  '../../../assets/images/leaders/TRAnand.png'


const AboutUsAssets = {
    BANNER,
    TRUSTED_BY:{
        CERTIFICATE:CERTIFICATE,
        AWS:AWS,
    },
    LEADERSHIP_TEAM:{
        CEO:CEO,
        COO:COO,
        CSO:CSO,
        CCO:CCO,
    },
    ADVISORY_BOARD:{
        TR_ANAND:TR_ANAND
    }
}

export default  AboutUsAssets;