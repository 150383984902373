import BANNER from  '../../../assets/images/contact-banner.png'
import BANGALORE from  '../../../assets/images/contact-1.png'
import AMERICA from  '../../../assets/images/contact-2.png'


const ContactUsAssets = {
    BANNER,
    GLOBAL_OFFFICES:{
        BANGALORE:BANGALORE,
        AMERICA:AMERICA,
    }
}

export default  ContactUsAssets;