import HOMESLIDER_FIND_TALENT from  '../../../assets/images/home_first.png'
import HOMESLIDER_GET_ACCESS from  '../../../assets/images/home_second.png'
import HOMESLIDER_UPSKILL from  '../../../assets/images/home_third.png'
import HOMESLIDER_HR_SERVICE from  '../../../assets/images/hr-services/hr_banner.png'
import TRUSTED_BRAND from  '../../../assets/images/trust-banner.png'
import TRUSTED_BRAND_MOBILE from  '../../../assets/images/trust-banner-mobile.png'
import JOB_SEEKER_WORKFLOW from  '../../../assets/images/partner-work.png'
import JOB_SEEKER_WORKFLOW_TABLET from  '../../../assets/images/partner-work-tablet.png'
import JOB_SEEKER_WORKFLOW_MOBILE from  '../../../assets/images/partner-work-mobile.png'
import COMPANY_WORKFLOW from  '../../../assets/images/company-work-flow.png'
import COMPANY_WORKFLOW_TABLET from  '../../../assets/images/company-work-flow-tablet.png'
import COMPANY_WORKFLOW_MOBILE from  '../../../assets/images/company-work-flow-mobile.png'
import TALENT_DEVELOPMENT from '../../../assets/images/home-icon1.png'
import BEST_TALENT_FIT from '../../../assets/images/home-icon2.png'
import CURATED_PROGRAM from '../../../assets/images/home-icon3.png'
import AI_POWERED_MATHC from '../../../assets/images/home-icon4.png'
import IN_DEMAND_SKILLS from '../../../assets/images/home-company.png'
import CERTIFICATE from '../../../assets/images/certificate.png'
import AWS from '../../../assets/images/aws.png'
import TESTIMONIAL_PALAK_JAIN from '../../../assets/testimonial/palak_jain.png'
import TESTIMONIAL_PANKAJ_SHARMA from '../../../assets/testimonial/pankaj_sharma.png'


const HomeAssets = {
    SLIDER:{
        HOMESLIDER_FIND_TALENT,
        HOMESLIDER_GET_ACCESS,
        HOMESLIDER_UPSKILL,
        HOMESLIDER_HR_SERVICE
    },
    TRUSTED_BRAND:{
        DESKTOP: TRUSTED_BRAND,
        MOBILE: TRUSTED_BRAND_MOBILE,
    },
    WORKFLOW:{
        JOB_SEEKER:{
            DESKTOP: JOB_SEEKER_WORKFLOW,
            TABLET: JOB_SEEKER_WORKFLOW_TABLET,
            MOBILE: JOB_SEEKER_WORKFLOW_MOBILE,
        },
        COMPANY:{
            DESKTOP: COMPANY_WORKFLOW,
            TABLET: COMPANY_WORKFLOW_TABLET,
            MOBILE: COMPANY_WORKFLOW_MOBILE,
        }
    },
    FEATURES:{
        TALENT_DEVELOPMENT: TALENT_DEVELOPMENT,
        BEST_TALENT_FIT: BEST_TALENT_FIT,
        CURATED_PROGRAM: CURATED_PROGRAM,
        AI_POWERED_MATHC: AI_POWERED_MATHC,
        IN_DEMAND_SKILLS: IN_DEMAND_SKILLS
    },
    TRUSTED_BY:{
        CERTIFICATE:CERTIFICATE,
        AWS:AWS,
    },
    TESTIMONIAL:{
        PALAK_JAIN:TESTIMONIAL_PALAK_JAIN,
        PANKAJ_SHARMA:TESTIMONIAL_PANKAJ_SHARMA,
    }
}

export default  HomeAssets;