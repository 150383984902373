import { css } from "@emotion/react";
import { AppColors } from "../../../utils/consts/app_colors";


const TrustedByStyles = css({
        ".trustedby-title":{
            color:AppColors.PRIMARY_MAIN,
            fontWeight:'bolder',
        }
    });


export default TrustedByStyles;