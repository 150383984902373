import Banner from '../../../assets/images/hr-services/hr_banner.png'
import TRUSTED_BRAND from  '../../../assets/images/trust-banner.png'
import TRUSTED_BRAND_MOBILE from  '../../../assets/images/trust-banner-mobile.png'
import WORKFLOW from '../../../assets/images/hr-services/hr-services-working.png'
import VISION from '../../../assets/images/hr-services/vision-mission-values.png'
import HR_SERVICES from '../../../assets/images/hr-services/hr-services.png'
import PERFORMANCE_MANAGEMENT  from '../../../assets/images/hr-services/performance-management.png'
import HR_OPERATIONS  from '../../../assets/images/hr-services/hr-operations.png'
import POLICY  from '../../../assets/images/hr-services/policy-and-compliances.png'
import CAREER_PATHING  from '../../../assets/images/hr-services/career-pathing.png'
import EMPLOYEE_ENGAGEMENT  from '../../../assets/images/hr-services/employee-engagement.png'
import CAMPUS_TO_CORPORATE  from '../../../assets/images/hr-services/campus-to-corporate-program.png'





const HRServicesAssets = {
    Banner,
    TrustedBrand:{
        Mobile:TRUSTED_BRAND_MOBILE,
        Desktop:TRUSTED_BRAND
    },
    WorkFlow:WORKFLOW,
    Features:{
        HRServices:HR_SERVICES,
        Vision:VISION,
        PerformanceManagement:PERFORMANCE_MANAGEMENT,
        HROperations:HR_OPERATIONS,
        Policy:POLICY,
        CareerPathing:CAREER_PATHING,
        EmployeeEngagement:EMPLOYEE_ENGAGEMENT,
        CampusToCorporate:CAMPUS_TO_CORPORATE,


    },
    Testimonial:{
    }

}


export default HRServicesAssets;