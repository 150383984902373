


const HOME = "/";
const COMPANIES = "/companies";
const JOB_SEEKERS = "/job-seekers";
const UPSKILLING_PARTNERS = "/upskillig-partners";
const HR_SERVICES = "/hr-services";
const BLOGS = "/blogs";
const BLOG = (id) => `/blogs/${id}`;
const ABOUT_US = "/about-us";
const COTACT_US = "/cotact-us";
const TERMS = "/terms-and-conditions";
const PRIVACY = "/privacy-policy";
const SITE_MAP = "/sitemap.xml";
const CAREERS = "#";
const COMMUNITY_FORUM = "#";
const LEARNING_LAB = "#";


const FIND_TALENT = "/find-talent";
const FIND_OPPORTUNITY = "/find-opportunity";
const BECOME_PARTNER = "/become-partner";
const HR_SERVICE_JOIN = "/hr-service-join";



const APP = "/app";

export const AppRoutes = {
    HOME,
    COMPANIES,
    JOB_SEEKERS,
    UPSKILLING_PARTNERS,
    HR_SERVICES,
    BLOGS,
    BLOG,
    ABOUT_US,
    COTACT_US,
    APP,
    TERMS,
    PRIVACY,
    SITE_MAP,
    CAREERS,
    COMMUNITY_FORUM,
    LEARNING_LAB,

    FIND_TALENT,
    FIND_OPPORTUNITY,
    BECOME_PARTNER,
    HR_SERVICE_JOIN

}