

const Liks = {
    PLAY_STORE_APP:"https://play.google.com/store/apps/details?id=com.digverve.uden",
    UDEN_FACEBOOK:"https://www.facebook.com/UnifiedDevelopmentAndEmploymentNetworkk",
    UDEN_INSTAGRAM:"https://instagram.com/uden.tech?utm_medium=copy_link",
    UDEN_LINKEDIN:"https://www.linkedin.com/company/uden-professional-s-permanent",
}

export const AppStrigs = {
    Liks,
}