import FIND_TALENT from  '../../../assets/images/find-talent.png'
import FIND_OPPORTUNITY from  '../../../assets/images/find-opportunity.png'
import BECOME_PARTNER from  '../../../assets/images/become-partner.png'
import TRANSFORM_YOUR_HR from  '../../../assets/images/hr-services/know-more.png'



const KnowMoreFromAssets = {
    FIND_TALENT,
    FIND_OPPORTUNITY,
    BECOME_PARTNER,
    TRANSFORM_YOUR_HR
}

export default  KnowMoreFromAssets;