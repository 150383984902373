import {css} from '@emotion/react';

export const BlogsStyles = {
    
    banner: css({
        
        
    }),
    list:css({
        justifyContent:'center',


    })
}



